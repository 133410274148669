module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Digital Dartington","short_name":"Digital Dartington","start_url":"/","background_color":"#fff","theme_color":"#843575","display":"standalone","icon":"static/logos/Dartington_logo_mobile.svg"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
